<template>
  <BaseLayout :breadcrumbs="breadcrumbs">
    <MeetingsSidebar slot="drawer-left" />
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="align-start flex-column">
            <h2 class="headline">
              {{ meeting.fullTitle }}
              <template v-if="meeting.location">· {{ meeting.location }}</template>
            </h2>
            <p class="caption">
              {{ $t('last_update_on', { date: toLocaleDateTimeString(meeting.imported_at) }) }}
            </p>
          </v-card-title>
          <v-card-text v-if="$config.features.meetingDescription" class="pt-0">
            <MeetingDescription :meeting="meeting" />
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-btn
              v-if="meeting.protocol_id && $vuetify.breakpoint.mdAndDown"
              text
              icon
              color="primary"
              :to="{ name: 'protocol', params: { id: meeting.protocol_id } }"
            >
              <v-icon>mdi-attachment</v-icon>
            </v-btn>
            <MeetingNotificationDialog :meeting="meeting">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  text
                  :icon="$vuetify.breakpoint.mdAndDown"
                  color="primary"
                  data-testid="btn-send-notification"
                  v-on="on"
                >
                  <v-icon v-if="$vuetify.breakpoint.mdAndDown">mdi-bell</v-icon>
                  <span v-else>{{ $t('Send_notification') }}</span>
                </v-btn>
              </template>
            </MeetingNotificationDialog>
            <v-btn text :icon="$vuetify.breakpoint.mdAndDown" color="primary" @click="download">
              <v-icon v-if="$vuetify.breakpoint.mdAndDown">mdi-cloud-download</v-icon>
              <span v-else>{{ $t('download_meeting') }}</span>
            </v-btn>
            <template v-if="meeting.committee.can_import_meeting">
              <v-spacer />
              <v-btn
                text
                :icon="$vuetify.breakpoint.mdAndDown"
                color="primary"
                :to="{ name: 'meeting_read_documents', params: { id: meeting.id } }"
              >
                <v-icon v-if="$vuetify.breakpoint.mdAndDown">mdi-eye</v-icon>
                <span v-else>{{ $t('Spread_documents') }}</span>
              </v-btn>
            </template>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-tabs ref="agendaItemTabs" v-model="computedAgendaItemMode" grow height="auto">
          <v-tab class="headline text-capitalize py-2" tab-value="business_directory">{{
            meeting.has_tagesordnung ? $t('business_directory') : $t('agenda_items')
          }}</v-tab>
          <v-tab
            v-show="meeting.has_tagesordnung"
            data-testid="agenda-tab-item"
            class="headline text-capitalize py-2"
            tab-value="agenda"
            >{{ $t('agenda') }}</v-tab
          >
        </v-tabs>
        <v-tabs-items v-model="computedAgendaItemMode" class="pt-2">
          <v-tab-item :transition="false" :reverse-transition="false" value="business_directory">
            <AgendaItemList :items="agendaItems" />
          </v-tab-item>
          <v-tab-item v-show="meeting.has_tagesordnung" :transition="false" :reverse-transition="false" value="agenda">
            <TagesordnungList :meeting="meeting" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col cols="12" md="6">
        <TitledCard v-if="meeting.protocol" :title="$t('meetingDocuments')">
          <!-- Show protocol when protocol is present -->
          <h3 class="body-2">{{ $t('protocol') }}</h3>
          <v-list data-testid="protocol-list" two-line class="py-0">
            <v-list-item :to="meeting.protocol.url">
              <v-list-item-avatar class="rounded-0">
                <v-icon>{{ `${meeting.protocol.mimeTypeIcon}` }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-wrap d-flex align-center">
                  {{ $t('show_protocol') }}
                  <v-icon
                    v-if="meeting.protocol.color_code"
                    class="ml-2"
                    :size="12"
                    :data-testid="`document_designation-icon-${meeting.protocol.title}`"
                    :color="meeting.protocol.color_code"
                  >
                    mdi-square-rounded
                  </v-icon>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{
                    $t('last_update_on', {
                      date: toLocaleDateTimeString(meeting.protocol.updated),
                    })
                  }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <TagList :tags="meeting.protocol.tags" />
                </v-list-item-subtitle>
              </v-list-item-content>
              <ListActionIndicator :item="meeting.protocol" />
            </v-list-item>
          </v-list>
          <h3 class="body-2 mt-3">{{ $t('other_meeting_documents') }}</h3>
          <MeetingDocumentList :items="meetingDocuments" data-testid="meeting-documents" />
        </TitledCard>
        <TitledCard v-else :title="$t('meetingDocuments')">
          <!-- Show flat documents when no protocol -->
          <MeetingDocumentList
            v-if="meetingDocuments.length"
            data-testid="meeting-documents"
            :items="meetingDocuments"
          />
          <p v-else class="grey--text text--darken-1">{{ $t('no_documents_existing') }}</p>
        </TitledCard>
        <NotesList class="mt-6" :committee-groups="committeeGroups" :notes="notes" @create="createNote" />
      </v-col>
    </v-row>
  </BaseLayout>
</template>

<script>
import joinPath from 'path.join'
import orderBy from 'lodash/orderBy'
import { toLocaleDateTimeString } from '@/filters'
import { createLink } from '@/api/helpers'
import downloadFile from '@/download'
import Meeting from '@/store/models/meeting'
import MeetingDocument from '@/store/models/meetingdocument'
import Committee from '@/store/models/committee'
import AgendaItem from '@/store/models/agendaitem'
import Note from '@/store/models/note'
import User from '@/store/models/user'
import Attachment from '@/store/models/attachment'
import Proposal from '@/store/models/proposal'
import Protocol from '@/store/models/protocol'
import { localeSort } from '@/sorters'
import { filterLatestVersions } from '@/version_utils'
import { api } from '@/api'
import { mapState, mapMutations } from 'vuex'

const downloadURLtemplate = '/committees/{committee}/meetings/{meeting}/download/'

export default {
  name: 'Meeting',
  async initialAsyncData({ id }) {
    const meeting = await Meeting.findOrFetch(id)
    const committee = await Committee.findOrFetch(meeting.committee_id)
    await Promise.all([User.fetchAllByModel(committee), Meeting.fetchAllByModel(committee)])
  },
  async asyncData({ id }) {
    const meeting = await Meeting.findOrFetch(id)
    await Promise.all([
      AgendaItem.fetchAllByModel(meeting),
      Note.fetchAllByModel(meeting),
      Attachment.fetchAllByModel(meeting),
      Proposal.fetchAllByModel(meeting),
      MeetingDocument.fetchAllByModel(meeting),
      Protocol.fetch(meeting.protocol_id),
    ])
  },
  data() {
    return {
      committeeGroups: [],
    }
  },
  computed: {
    ...mapState('application', ['agendaItemMode']),
    computedAgendaItemMode: {
      get() {
        if (!this.meeting.has_tagesordnung) {
          return 'business_directory'
        }
        return this.agendaItemMode
      },
      set(agendaItemMode) {
        this.set({ path: 'agendaItemMode', value: agendaItemMode })
      },
    },
    breadcrumbs() {
      const meeting = Meeting.query().with('committee').find(Number.parseInt(this.$route.params.id, 10))
      return [meeting.committee, meeting]
    },
    meeting() {
      return Meeting.query()
        .with('notes')
        .with('committee')
        .with('meetingDocuments')
        .with('protocol')
        .find(this.$route.params.id)
    },
    meetingDocuments() {
      const meetingDocuments = filterLatestVersions(this.meeting.meetingDocuments)
      if (this.$config.documents_use_sort_order) {
        return meetingDocuments.sort((a, b) => a.sort_order - b.sort_order)
      }
      return localeSort(meetingDocuments, 'title')
    },
    agendaItems() {
      return AgendaItem.query()
        .with('notes')
        .with('attachments.annotations')
        .with('proposal.annotations')
        .where('meeting_id', this.meeting.id)
        .orderBy('sort_order')
        .all()
    },
    toolbarTitle() {
      return this.meeting.fullTitle
    },
    notes() {
      return orderBy(
        [
          ...(this.meeting.notes || []), // Meeting notes
        ],
        (note) => new Date(note.created),
        'desc',
      )
    },
    downloadURL() {
      return joinPath(
        this.$config.path_prefix,
        this.$config.api_root,
        createLink(downloadURLtemplate, {
          committee: this.meeting.committee.id,
          meeting: this.meeting.id,
        }),
      )
    },
  },
  async mounted() {
    await this.setCommitteeGroups()
    this.$refs.agendaItemTabs.callSlider()
  },
  methods: {
    ...mapMutations('application', ['set']),
    toLocaleDateTimeString,
    download() {
      downloadFile(this.downloadURL)
    },
    createNote(note) {
      this.meeting.createNote(note)
    },
    async setCommitteeGroups() {
      const { data } = this.meeting.committee.id
        ? await api.get(createLink('committees/{id}/committee_groups', { id: this.meeting.committee.id }))
        : { data: [] }

      this.committeeGroups = data
    },
  },
  head() {
    return {
      title() {
        return { inner: this.meeting.fullTitle }
      },
    }
  },
}
</script>
